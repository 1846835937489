export const environment = {
  production: false,
  environmentName: 'qa',
  api: 'https://barometer-qa.syensqo.com',
  firebase: {
    apiKey: 'AIzaSyCTSvi-6S4_kVOqcS71Z37mRzr12R_ZI44',
    authDomain: 'gcp-sqo-barometer-u.firebaseapp.com',
    databaseURL: 'https://gcp-sqo-barometer-u.firebaseio.com',
    projectId: 'gcp-sqo-barometer-u',
    storageBucket: 'gcp-sqo-barometer-u.appspot.com',
    messagingSenderId: '380112219390',
    appId: '1:380112219390:web:1e7ce66ad2dde77c85c9af',
  },
  gtagTrackingId: 'UA-115813374-10',
};
