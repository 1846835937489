export const environment = {
  production: false,
  environmentName: 'qa',
  api: 'https://solvay-barometer-qa.appspot.com',
  firebase: {
    apiKey: 'AIzaSyDteUa8TSBTq1cayMPpEDWt78F1hH5KGEc',
    authDomain: 'solvay-barometer-qa.firebaseapp.com',
    databaseURL: 'https://solvay-barometer-qa.firebaseio.com',
    projectId: 'solvay-barometer-qa',
    storageBucket: 'solvay-barometer-qa.appspot.com',
    messagingSenderId: '380112219390',
    appId: '1:380112219390:web:1e7ce66ad2dde77c85c9af',
  },
  gtagTrackingId: 'UA-115813374-10',
};
